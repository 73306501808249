import { Reducer } from "react";
import { ActionTypes, ApplicationActions } from "./actions/common";
import { ApplicationState } from "../state/applicationState";

export const appReducer: Reducer<ApplicationState, ApplicationActions> = (state: ApplicationState, action: ApplicationActions): ApplicationState => {
    switch (action.type) {
        // user
        case ActionTypes.GET_GOOGLE_USER_ID:
            break;
        case ActionTypes.SET_USER:
            state.userState = { ...state.userState, isLoggedIn: action.isLoggedIn, userId: action.userId };
            break;
        case ActionTypes.GET_LOGIN_CONFIG:
            break;
        // query
        case ActionTypes.POST_QUERY:
            break;
        case ActionTypes.SET_QUERY_RESPONSES:
            state.queryState = action.payload;
            break;
        // browse
        case ActionTypes.POST_BROWSE:
            break;
        case ActionTypes.SET_BROWSE_MATERIAL:
            state.browseState.material = action.payload;
            break;
        case ActionTypes.SET_BROWSE_MESSAGES:
            state.browseState.messages = action.payload;
            break;
        case ActionTypes.SET_BROWSE_CHOSEN:
            if (state.browseState.messages.length > 0) {
                state.browseState.messages[state.browseState.messages.length - 1].chosen = action.payload;
            }
            break;
        case ActionTypes.PUSH_BROWSE_MESSAGE:
            state.browseState.messages.push(action.payload);
            break;
        case ActionTypes.POP_BROWSE_MESSAGE:
            if (state.browseState.messages.length > 0) {
                state.browseState.messages.pop();
            }
            break;
        // frame
        case ActionTypes.POST_FRAME:
            break;
        case ActionTypes.SET_FRAME_RESPONSES:
            state.frameState = action.payload;
            break;
        // constellation
        case ActionTypes.SAVE_SELECTED_FRAMEWORKS:
            break;
        case ActionTypes.GET_CONSTELLATION:
            break;
        case ActionTypes.DELETE_CONSTELLATION:
            break;
        case ActionTypes.SET_CONSTELLATION:
            state.userState.constellation = action.constellation;
            break;
        case ActionTypes.SET_CONSTELLATION_NAME:
            state.userState.constellationName = action.constellationName;
            break;
        // cluster
        case ActionTypes.GET_CLUSTERS:
            break;
        case ActionTypes.SET_CLUSTERS:
            state.userState.clusters = action.clusters;
            break;
        case ActionTypes.CLUSTER_BY:
            break;
        case ActionTypes.GET_CLUSTER_BY_OPTIONS:
            break;
        case ActionTypes.GET_CLUSTER_BY_SUGGESTION:
            break;
        case ActionTypes.SET_CLUSTER_BY:
            state.userState.clusterBy = action.clusterBy;
            break;
        // display
        case ActionTypes.SET_UPDATED:
            state.userState.updated = action.updated;
            break;
        // note
        case ActionTypes.SET_SELECTED_CONTENT:
            state.userState.selectedContent = action.selected;
            break;
        case ActionTypes.EDIT_FRAMEWORK:
            break;
        case ActionTypes.DELETE_FRAMEWORK:
            break;
        default:
            console.log("reducer state not updated {action.type}")
            break;
    }
    return { ...state};
}

export default appReducer;